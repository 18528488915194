.container {
  display: grid;
  // grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas: 'logo menu other icons';

 
  @media (max-width: 767px) {
    grid-template-areas:
      'logo logo logo'
      'menu other icons';
 
  }
}

.logo {
  grid-area: logo;
}

.menu {
  grid-area: menu;

}

.other {
  grid-area: other;
 
}
.icons {
  grid-area: icons;
}
.image{
  max-width: unset;
}