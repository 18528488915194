.logoutButton:hover .logoutIcon > path {
  fill: black !important;
}
.logoutButton:hover > p {
  color:#111136 !important
}
.hoverState {
  transition: 0.7s;
  background-color: #014A8D;
  z-index: -1;
}

.menuItem:hover .hoverState {
  width: 100%;
  background: #014A8D !important;
}
.logoutIcon > path {
  fill: white !important;
}
.menuItem:hover  {
 .icon {
   color: white;
 }
}

