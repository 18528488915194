.search_box {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.input_search {
  height: 30px;
  width: 30px;
  border-radius: 10px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  // background-color: #f8a609;
  background-color: #f7f7f7;
  padding-right: 20px;
  color: black;
  &:focus {
    width: 250px;
    border-radius: 5px;
    background-color: #f7f7f7;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
  }
}

.input_search_focused {
  width: 250px !important;
  border-radius: 5px !important;
  background-color: #f7f7f7 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2) !important;
}

.btn_search {
  width: 30px;
  height: 30px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #1b57ed;
  background-color: transparent;
  pointer-events: painted;
}

.btn_search_focused {
  ~ .input_search {
    width: 250px !important;
    border-radius: 5px !important;
    background-color: white !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
    transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2) !important;
  }
}
