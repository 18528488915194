.container {
  &:hover {
    .footer {
      color: white;
      opacity: 0;
    }
    .btns {
      display: flex;
      background-color: #014A8D !important;
    }

    .video {
      display: none;
    }
    .icon {
      color: white;
    }
  }
}
